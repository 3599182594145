export const Menu=[
{   "MenuName":"Home",
    "Path":"/home"
},
{"MenuName":"Work Queue",
"Path":"/work-queue",
"Context":"workQueue"
},
{"MenuName":"Query",
"Path":"/query",
"Context":"query"
},
{"MenuName":"Analytics",
"Path":"/analytics",
"Context":"analytics"
},

{"MenuName":"Admin Task",
"submenu":[
    {"MenuName":"Priority Admin",
    "Path":"/admin-task/priority-admin",
    "Context":"priorityAdmin"
    },
    {  
    "MenuName":"Control Table",
    "Path":"/admin-task/control-table",
     "Context":"controlTable"
    },
    {  "MenuName":"Source Setting",
        "Path":"/admin-task/source-setting",
        "Context":"sourceSetting"
    },
    {  "MenuName":"Security",
    "Path":"/admin-task/security",
    "Context":"security"
},
    

]
}



]